//=================================
// font
//=================================
$font_1: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font_2: "Oswald", sans-serif;

// コンテンツ幅
$content_width: 1420px;
// $pc_bp以上がPC表示
$pc_bp: 1025px;
// $sp_bp以下がSP表示
$sp_bp: 767px;

$viewport: 375; // 基準となるビューポート
@function pv($num, $width: $viewport) {
  @return (1vw * $num / $width * 100);
}
//$viewport_tab: 1680; // 基準となるビューポート
$viewport_tab: 1420; // 基準となるビューポート
@function pv2($num, $width: $viewport_tab) {
  @return (1vw * $num / $width * 100);
}
$viewport_pc: 1920; // 基準となるビューポート
@function pv3($num, $width: $viewport_pc) {
  @return (1vw * $num / $width * 100);
}
$viewport_pc: 1921; // 基準となるビューポート
@function pv4($num, $width: $viewport_pc) {
  @return (1vw * $num / $width * 100);
}


//=================================
// color
//=================================
$black: #000;
$black_2: #333;
$black_3: rgb(68, 68, 68);
$white: #fff;
$color_1: #00a0e9;
$color_2: #ffe100;
$color_3: #d6d6d6;
$color_4: #0071bc;
$color_5: #b7b7b7;
