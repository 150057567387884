@use "setting" as *;

.l_kv {
  background: url(../img/contact/kv.jpg) no-repeat center / 100%;
}

.sec {
  &._1 {
    padding: pv3(140) 0 0;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20) 0;
    }
    .box_1 {
      display: flex;
      gap: pv3(30);
      margin: 0 auto pv3(145);
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        margin: 0 auto pv(60);
        max-width: 100%;
      }
      .item {
        width: calc(33.333% - pv3(30));
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .ttl {
          padding: pv3(25) 0;
          border-radius: pv3(30) pv3(30) 0 0;
          background-color: $color_2;
          @media only screen and (max-width: 767px) {
            padding: pv(20) 0;
            border-radius: pv(30) pv(30) 0 0;
          }
          span {
            display: block;
            margin-top: pv3(5);
            @media only screen and (max-width: 767px) {
              margin-top: pv(5);
            }
          }
        }
        iframe {
          width: 100%;
          height: pv3(406);
          @media only screen and (max-width: 767px) {
            height: pv(280);
          }
        }
      }
    }
  }
  &._2 {
    padding: pv3(140) 0 pv3(200);
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .inner {
      margin: 0 auto;
      max-width: pv3(1120);
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
      &.page {
        font-size: pv3(24);
        line-height: pv3(42);
        @media only screen and (max-width: 767px) {
          font-size: pv(16);
          line-height: pv(28);
        }
        h1 {
          margin: 0 0 pv3(20);
          font-size: pv3(50);
          @media only screen and (max-width: 767px) {
            margin: 0 0 pv(20);
            font-size: pv(28);
          }
        }
        h2 {
          margin: pv3(40) 0;
          padding-bottom: pv3(15);
          border-bottom: 2px solid $color_1;
          color: $color_1;
          font-size: pv3(30);
          @media only screen and (max-width: 767px) {
            margin: pv(30) 0 pv(20);
            font-size: pv(26);
          }
          &:first-child {
            margin: 0 0 pv3(40);
            @media only screen and (max-width: 767px) {
              margin: 0 0 pv(20);
            }
          }
        }
        h3 {
          margin: pv3(40) 0;
          padding: pv3(10);
          background-color: $color_1;
          color: $white;
          font-size: pv3(26);
          @media only screen and (max-width: 767px) {
            margin: pv(30) 0 pv(20);
            padding: pv(10);
            font-size: pv(22);
          }
          &:first-child {
            margin: 0 0 pv3(40);
            @media only screen and (max-width: 767px) {
              margin: 0 0 pv(20);
            }
          }
        }
        h4 {
          margin: pv3(40) 0;
          padding-left: pv3(20);
          border-left: 5px solid $color_1;
          font-size: pv3(24);
          @media only screen and (max-width: 767px) {
            margin: pv(30) 0 pv(20);
            padding-left: pv(15);
            font-size: pv(20);
          }
          &:first-child {
            margin: 0 0 pv3(40);
            @media only screen and (max-width: 767px) {
              margin: 0 0 pv(20);
            }
          }
        }
        p {
          & + p {
            margin-top: pv3(30);
            @media only screen and (max-width: 767px) {
              margin-top: pv(20);
            }
          }
        }
        a:not([class]) {
          color: $color_1;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        ul:not([class]),
        ol:not([class]),
        ul.wp-block-list,
        ol.wp-block-list {
          margin: pv3(20) 0;
          counter-reset: ol_li;
          @media only screen and (max-width: 767px) {
            margin: pv(10) 0;
          }
        }
        .noborder ul:not([class]),
        .noborder ol:not([class]) {
          margin: 0;
          counter-reset: ol_li;
        }
        ul:not([class]) > li,
        ol:not([class]) > li,
        ul.wp-block-list > li,
        ol.wp-block-list > li {
          position: relative;
          padding-left: pv3(30);
          list-style-type: none;
          @media only screen and (max-width: 767px) {
            padding-left: pv(20);
          }
        }
        ul:not([class]) > li + li,
        ol:not([class]) > li + li,
        ul.wp-block-list > li + li,
        ol.wp-block-list > li + li {
          margin-top: pv3(4);
          @media only screen and (max-width: 767px) {
            margin-top: pv(4);
          }
        }
        ul:not([class]) > li:before,
        ul.wp-block-list > li:before {
          position: absolute;
          top: pv3(10);
          left: 0;
          display: inline-block;
          width: pv3(8);
          height: pv3(8);
          background: $color_1;
          content: "";
          @media only screen and (max-width: 767px) {
            top: pv(9);
            width: pv(8);
            height: pv(8);
          }
        }
        ol:not([class]) > li:before,
        ol.wp-block-list > li:before {
          position: absolute;
          top: 0;
          left: 0;
          color: $color_1;
          content: counter(ol_li) ".";
          counter-increment: ol_li;
          font-weight: bold;
        }
        ol:not([class]) > li,
        ol:not([class]) > li {
          padding-left: pv3(35);
          ol:not([class]) > li:before,
          ol.wp-block-list > li:before {
            position: absolute;
            top: 0;
            left: 0;
            color: #a7a7a7;
            content: "(" counter(ol_li) ")";
            counter-increment: ol_li;
            font-weight: bold;
          }
        }
        table {
          margin: pv3(20) auto;
          th {
            border: 1px solid $black;
            background-color: $color_1;
            color: $white;
          }
        }
        .wp-block-table thead {
          border: none;
        }
        p {
          & + p {
            margin-top: 19px;
            @media only screen and (max-width: 767px) {
              margin-top: pv(29);
            }
          }
        }
      }
    }
    .c_btn_4 {
      & + .c_btn_4 {
        margin-top: pv3(88);
      }
    }
  }
  .inner_2 {
    margin: 0 auto;
    max-width: pv3(800);
    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &._3 {
    position: relative;
    padding: 0;
    @media only screen and (max-width: 767px) {
      padding: 0 pv(20);
    }
    .box_1 {
      display: flex;
      gap: pv3(30);
      margin: 0 auto pv3(145);
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        margin: 0 auto pv(60);
        max-width: 100%;
      }
      .item {
        width: calc(33.333% - pv3(30));
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .ttl {
          padding: pv3(25) 0;
          border-radius: pv3(30) pv3(30) 0 0;
          background-color: $color_2;
          @media only screen and (max-width: 767px) {
            padding: pv(20) 0;
            border-radius: pv(30) pv(30) 0 0;
          }
          span {
            display: block;
            margin-top: pv3(5);
            @media only screen and (max-width: 767px) {
              margin-top: pv(5);
            }
          }
        }
        iframe {
          width: 100%;
          height: pv3(406);
          @media only screen and (max-width: 767px) {
            height: pv(280);
          }
        }
      }
    }
    .c_ttl_1 {
      &._2 {
        margin-bottom: pv3(23);
        @media only screen and (max-width: 767px) {
          margin-bottom: pv(20);
        }
      }
    }
    .box_2 {
      display: flex;
      gap: pv3(30);
      margin: pv3(69) auto pv3(48);
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        margin: pv(30) auto pv(40);
        max-width: 100%;
      }
      .item {
        padding: pv3(32) 0 pv3(40);
        width: calc(33.333% - pv3(30));
        border-radius: pv3(10);
        background-color: $white;
        @media only screen and (max-width: 767px) {
          padding: pv(20) 0;
          width: 100%;
          border-radius: pv(10);
        }
        .ttl {
          span {
            display: block;
            margin-top: pv3(5);
          }
        }
        img {
          display: block;
          margin: pv3(14) auto pv3(24);
          width: pv3(312);
          @media only screen and (max-width: 767px) {
            margin: pv(10) auto pv(15);
            width: pv(300);
          }
        }
        .flex_1 {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: pv3(19);
          margin-bottom: pv3(21);
          @media only screen and (max-width: 767px) {
            gap: pv(10);
            margin-bottom: pv(15);
          }
        }
        .flex_2 {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: pv3(30);
          @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: pv(10);
          }
          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: pv3(138);
            height: pv3(48);
            border: 1px solid $color_1;
            border-radius: pv3(3);
            background-color: $white;
            transition: background-color .3s, color .3s;
            @media only screen and (max-width: 767px) {
              width: pv(250);
              height: pv(40);
            }
            &:hover {
              @media only screen and (min-width: 768px) {
                background-color: $color_1;
                color: $white;
              }
            }
          }
        }
      }
    }
    .c_btn_1 {
      &:hover {
        background-color: transparent;
        color: #00a0e9;
      }
    }
    .note {
      display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: pv3(583);
  height: pv3(72.4);
  border: 1px solid $white;
  border-radius: pv3(10);
  background-color: transparent;
  color: $white;
  transition: background-color .3s, color .3s;
  @media only screen and (max-width: 767px) {
    padding: pv(15);
    width: pv(335);
    height: auto;
    border-radius: pv(10);
  }
  }
    }
}
